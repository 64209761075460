.label {
    color: #f7ec1f;
    font-size: 1.25rem;
    

    .text-input {
        position: relative;
        display: block;
        width: 100%;
        padding: 1rem;
        border-radius: 5px;
        outline: none;
        background-color: #f7ec1f;
        border: none;
        max-width: 800px;
        margin-bottom: 1rem;
        box-sizing: border-box;

        @media only screen and (max-width: 768px) {
            max-width: unset;
        }
    }
}