@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
    font-family: 'Lato', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

div {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}