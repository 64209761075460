.footer {
    position: relative;
    display: block;
    background-color: #0f151b;
    overflow: hidden;
    text-align: center;
    padding: 4rem 0rem;

    ul {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 768px) {
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            text-align: left;
            padding-left: 1.5rem;
        }

        li {
            list-style: none;
            color: #fff;
            padding: 0rem 1rem;
            font-size: 0.75rem;

            @media only screen and (max-width: 768px) {
                padding: 0rem;
                width: 50%;
                font-size: 0.75rem;
                padding-top: 0.5rem;
            }
        }
    }

    .icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

        @media only screen and (max-width: 768px) {
            justify-content: flex-start;
            margin-left: 1.5rem;
        }

        .icon {
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 25px;
            border: 1px solid #fff;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding-top: 3px;
            font-size: 20px;

            &:nth-child(2) {
                margin: 0rem 1rem;
            }
        }
    }
}