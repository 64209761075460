.image-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 35rem;

    @media only screen and (max-width: 768px) {
        height: 20rem;
    }

    .wrapper {
        position: relative;
        width: 90%;
        max-width: 1140px;
        height: inherit;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h3 {
            color: #f7ec1f;
            font-size: 3.75rem;

            @media only screen and (max-width: 768px) {
                font-size: 2.25rem;
            }
        }
    }
}

.body {
    
    position: relative;
    display: block;
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    overflow: hidden;
    color: #f7ec1f;
    padding: 1rem 0rem;
}