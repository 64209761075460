.SDFFestival {
    position: relative;
    display: flex;
    align-items: end;
    justify-content: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 100%;

    @media only screen and (max-width: 768px) {
        height: unset;
    }

    .SDFFestival-image {
        position: relative;
        display: block;
        width: 65%;
        background-position: center right;
        background-size: cover;
        height: inherit;

        @media only screen and (max-width: 768px) {
            width: 100%;
            height: 15rem;
        }
    }

    .SDFFestival-text {
        position: relative;
        display: block;
        width: 35%;
        max-width: 358.4px;
        margin: auto;

        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: unset;
            padding: 1rem;
        }

        .title-wrapper {
            position: 'relative'; 
            display: 'block';
            width: '100%';
            box-sizing: 'border-box';
            height: 13rem;

            @media only screen and (max-width: 768px) {
                height: unset;
            }
         
            .title {
                position: absolute;
                display: block;
                text-align: right;
                font-size: 2.25rem;
                top: 0%;
                left: -70%;
                width: '100%';
                box-sizing: border-box;

                @media only screen and (max-width: 768px) {
                    position: relative;
                    top: 0%;
                    left: 0;
                    font-size: 1.5rem;
                    text-align: left;
                }
                
                h3  {
                    font-weight: 700;
                    line-height: 58px;

                    @media only screen and (max-width: 768px) {
                        line-height: unset;
                    }
                }
            }
        }
       
        .paragraph-wrapper {

            width: 100%;
            
            .paragraph {
                line-height: 32px;
            }
        }
    }

}