.contactus-header {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #0f151b;
    color: #fff;
    height: 22rem;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        height: 18rem;
    }

    .yellow-top {
        border-top: 2px solid #f7ec1f;
        padding-top: 0.5rem;
        display: inline-block;
        
    }

    h3 {
        font-size: 4rem;

        @media only screen and (max-width: 768px) {
            font-size: 3rem;
        }
    }
}

.contactus-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    flex-direction: row-reverse;

    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }

    .form-body {
        position: relative;
        display: block;
        width: 50%;
        max-width: 570px;
        margin: 0 auto;

        @media only screen and (max-width: 768px) {
            max-width: unset;
            width: 100%;
            padding: 1rem;
        }

        .inputText {
            position: relative;
            display: block;
            width: 100%;
            background: none;
            appearance: none;
            border: none;
            box-sizing: border-box;
            padding: 1rem 0rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #f1efef;
            font-size: 1.0rem;
            outline: none;
        }

        .textarea {
            position: relative;
            display: block;
            width: 100%;
            background: none;
            appearance: none;
            border: none;
            box-sizing: border-box;
            border-bottom: 1px solid #f1efef;
            resize: none;
            outline: none;
            font-size: 1.0rem;
            margin-bottom: 3rem;
        }

    }
  

    .map {
        box-sizing: border-box;
        @media only screen and (max-width: 768px) {
            width: 100%;
            
            margin: 0 auto;
        }

        div:nth-child(1) {
            position: relative !important;
            width: 600px;
            height: 800px;

            @media only screen and (max-width: 768px) {
                width: '100%';
                height: 400px;
                margin: 0 auto;
            }
        }
        
    }
}



// AIzaSyCy0hVIFCorViVoiLo90p0SXcgnNEfSFfs