

.hero-image-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #0f151b;
    min-height: 100vh;

    .arrow {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        appearance: none;
        background-color: #f7ec1f;
        border: none;
        padding: 0.25rem 1.5rem;
        font-size: 1.5rem;
        border-radius: 10px;
        cursor: pointer;
        z-index: 10;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .arrow.right {
        top:50%;
        right: 3%;
    }

    .arrow.left {
        top:50%;
        left: 3%;
    }

    .slick-next {
        display: none;
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 980px;
        height: 100vh;
        margin: 0 auto;
        outline: none;
       

        @media only screen and (max-width: 768px) {
            padding: 1rem;
        }

        
        h3 {
            color: #fff;
            font-size: 4.5rem;
            margin: 0;

            @media only screen and (max-width: 768px) {
                font-size: 3rem;
            }
        }
        
        p {
            color: #8e8e8e;
            padding: 0;
            margin: 0;
        }

        .myButton {
            margin-top: 2rem;
        }
    }

    .slick-dots {
        display: block;
        width: 100%;
        bottom: 0;
        
        margin: 0 auto;
    
        li {
            margin: 5px 5px;
            button {
                
                &:before {
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
    }
}

