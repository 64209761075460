.workshop-wrapper {
    .workshops {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
        
        .workshops-text {
            text-align: right;
    
            .yellow-top {
                border-top: 2px solid #f7ec1f;
                padding-top: 1.25rem;
                display: inline-block;
                @media only screen and (max-width: 768px) {
                    padding-top: 1.0rem;
                }
            }
    
            .title {
                color: #000;
                font-size: 4.5rem;
                margin: 0;

                @media only screen and (max-width: 768px) {
                    font-size: 2.5rem;
                }
            }
        
            .date {
                color: #000;
                font-size: 1.5rem;
                margin: 0;
            }
        
            .paragraph {
                color: #000;
                
            }
        }
    }
    
    .slick-dots {
        width: 46px;
        right: 6%;
        height: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        li {
            margin: 5px 5px;
            button {
                &:before {
                    font-size: 12px;
                }
            }
        }
    }
}
