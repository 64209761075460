.header {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 2rem;
    background-color: #0f151b;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        padding: 0.75rem 1rem;
    }
    
    .logo-img {
        max-width: 100%;
        height: 60px;
    }

    .header-ul {

        display: flex;
        flex-direction: row;

        .header-li {
            list-style: none;
            padding-right: 2.25rem;
            font-size: 0.75rem;

            @media only screen and (max-width: 768px) {
                display: none;
            }
            
            a {
                color: #fff;
                font-weight: 400;

                &.active {
                    color: #f7ec1f;
                }
            }

            &:last-child {
                padding-right: 0rem;

                @media only screen and (max-width: 768px) {
                    display: block;
                    font-size: 1.75rem;
                    color: #f7ec1f;
                }
            }
        }
    }
}