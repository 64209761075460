.packages {
    background-color: #0f151b;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    max-width: 1140px;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
        height: unset;
        margin-top: 5rem;
        padding: 1rem;
    }

    .wrapper {
        background-color: #8e8e8e;
        text-align: center;
        padding: 3rem 2rem;
        border-radius: 30px;
        height: 28rem;
        width: 30%;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
            height: unset;
            width: 100%;
            margin: 1rem 0rem;
        }
        
        h3 {
            font-size: 1.5rem;
        }
    }

    .wrapper.active {
        background-color: #f7ec1f;
    }
}