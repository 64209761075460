.myButton {
    position: relative;
    display: block;
    background-color: #f7ec1f;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0.75rem 2rem;
    width: 100%;
    max-width: 160px;

    .btn-text {
        color: #0f151b;
        font-weight: 700;
        
    }
}