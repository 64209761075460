.sideMenu {
    position: fixed;
    display: none;
    align-items: end;
    padding-top: 4rem;
    justify-content: flex-start;
    top:0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,1);
    transition: opacity 0.3s;

    ul {
        
        li {
            list-style: none;
            color: #fff;
            padding: 0.75rem 0rem;
            font-size: 1.5rem;
            font-weight: 900;
        }
    }

    .iconTimes {
        position: absolute;
        display: block;
        top: 5%;
        right: 5%;
        z-index: 99;
        color: #fff;
        font-size: 2.5rem;
        cursor: pointer;
    }
}

.sideMenu.block {
    display: flex;
}

.sideMenu.none {
    display: none;
}