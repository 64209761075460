.TheFestival {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #0f151b;

    @media only screen and (max-width: 768px) {
        height: unset;
        flex-wrap: wrap;
    }

    .text {
        position: relative;
        display: block;
        width: 60%;
        color: #fff;
        max-width: 684px;
        margin: 0 auto;

        @media only screen and (max-width: 768px) {
            width: 100%;
            max-width: unset;
            padding: 1rem;
        }

        .yellow-top {
            border-top: 2px solid #f7ec1f;
            padding-top: 1.25rem;
            display: inline-block;
        }

        .title {
            font-size: 4rem;
            margin: 0;

            @media only screen and (max-width: 768px) {
                font-size: 3rem;
            }
        }

        .date {
            font-size: 1.5rem;
            margin: 0;
        }

        .paragraph {
            color: #8e8e8e;
            line-height: 32px;
        }
    }

    .image {
        position: relative;
        display: block;
        width: 40%;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }
}